import { NgModule } from '@angular/core';
import { Location } from '@angular/common';
import { Routes, RouterModule, Router } from '@angular/router';
import { GuestWishlistComponent } from './guest-wishlist/guest-wishlist.component';
import { VendorComponent } from './vendor/vendor.component';
import { LoginSignupComponent } from './shared/login-signup/login-signup.component';
import { AuthGuard } from './guards/auth.guard';
import { CustomerGuard } from './guards/customer.guard';
import { GivexGuard } from './guards/givex.guard';
import { TsGuard } from './guards/ts.guard';
import { ForgotPasswordComponent } from './shared/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './shared/reset-password/reset-password.component';
import { CommonPageComponent } from './footer/common-page/common-page.component';
import { GiftCardBalanceComponent } from './gift-card-balance/gift-card-balance.component';
import { TsGiftCardBalanceComponent } from './ts-gift-card-balance/ts-gift-card-balance.component';
import { CommonRouteIdentifierComponent } from './common-route-identifier/common-route-identifier.component';

const routes: Routes = [

  // { path: 'vendor/:id', component: VendorComponent },
  // { path: 'wishlist', component: GuestWishlistComponent },
  // { path: 'signin-signup', component: LoginSignupComponent },
  // { path: 'forgot-password', component: ForgotPasswordComponent },
  // { path: 'reset-password/:token', component: ResetPasswordComponent },
  // { path: 'pages/:id', component: CommonPageComponent },
  // {
  //   path: 'user',
  //   canActivate: [AuthGuard, CustomerGuard],
  //   loadChildren: () => import('./user/user.module').then(m => m.UserModule)
  // },

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
              anchorScrolling: 'enabled',
              onSameUrlNavigation: 'reload',
              scrollPositionRestoration: 'enabled'
            })],
  exports: [RouterModule],
    entryComponents: [
        LoginSignupComponent,
        GuestWishlistComponent,
        VendorComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        CommonPageComponent,
        GiftCardBalanceComponent,
        TsGiftCardBalanceComponent
    ]
})

export class AppRoutingModule {

    constructor( private window: Window, private location: Location, private router: Router){
      this.configureStore();
    }

    async configureStore(){
        if(this.window["data"]["allowSubFolderUrls"] == 2){
            routes.push(
                {
                    path: ':slug/:lang',
                    children: [
                      	{ path: 'givex-portal', canActivate: [GivexGuard], loadChildren: () => import('./givex-portal/givex-portal.module').then(m => m.GivexPortalModule)},
                        { path: 'ts-portal', canActivate: [TsGuard], loadChildren: () => import('./ts-portal/ts-portal.module').then(m => m.TsPortalModule)},
                        { path: 'vendor/:id', component: VendorComponent },
                        { path: 'wishlist', component: GuestWishlistComponent },
                        { path: 'signin-signup', component: LoginSignupComponent },
                        { path: 'forgot-password', component: ForgotPasswordComponent },
                        { path: 'reset-password/:token', component: ResetPasswordComponent },
                        { path: 'gift-card-balance', component: GiftCardBalanceComponent },
                        { path: 'ts-gift-card-balance', component: TsGiftCardBalanceComponent },
                        { path: 'pages/:id', component: CommonPageComponent },
                        { path: 'card_topup',
                            loadChildren: () => import('./single-page-checkout/single-page-checkout.module').then(m => m.SinglePageCheckoutModule)},
                        { path: 'card-topup', redirectTo: 'card_topup', pathMatch: 'full'},
                        { path: 'card_activation',
                            loadChildren: () => import('./single-page-checkout/single-page-checkout.module').then(m => m.SinglePageCheckoutModule)},
                        { path: 'card-activation', redirectTo: 'card_activation', pathMatch: 'full'},
                        { path: 'user',
                            canActivate: [AuthGuard, CustomerGuard],
                            loadChildren: () => import('./user/user.module').then(m => m.UserModule)
                        },
                        { path: 'category/:id',
                            loadChildren: () => import('./product-listing/product-listing.module').then(m => m.ProductListingModule)},
                        { path: 'catalogsearch/result/:term',
                            loadChildren: () => import('./product-listing/product-listing.module').then(m => m.ProductListingModule)},
                        { path: 'cart',
                            loadChildren: () => import('./cart/cart.module').then(m => m.CartModule) },
                        { path: 'checkout',
                            loadChildren: () => import('./checkout/checkout.module').then(m => m.CheckoutModule)} ,
                        { path: ':category/:id/:sub',
                            loadChildren: () => import('./product-listing/product-listing.module').then(m => m.ProductListingModule)},
                        { path: ':category/:id/:sub/:sub1',
                            loadChildren: () => import('./product-listing/product-listing.module').then(m => m.ProductListingModule)},
                        { path: ':category/:id/:sub/:sub1/:sub2',
                            loadChildren: () => import('./product-listing/product-listing.module').then(m => m.ProductListingModule)},
                        { path: ':category/:id/:sub/:sub1/:sub2/:sub3',
                            loadChildren: () => import('./product-listing/product-listing.module').then(m => m.ProductListingModule)},
                        { path: ':category/:id/:sub/:sub1/:sub2/:sub3/:sub4',
                            loadChildren: () => import('./product-listing/product-listing.module').then(m => m.ProductListingModule)},
                        { path: ':category/:id/:sub/:sub1/:sub2/:sub3/:sub4/:sub5',
                            loadChildren: () => import('./product-listing/product-listing.module').then(m => m.ProductListingModule)},
                        { path: ':category/:id',
                            loadChildren: () => import('./product-listing/product-listing.module').then(m => m.ProductListingModule)},
                        {
                            path: ':id',
                            component: CommonRouteIdentifierComponent
                        }
                    ]
                }
            )

        }else if(this.window["data"]["allowSubFolderUrls"] == 1){
            routes.push(
                {
                    path: ':slug',
                    children: [
                        { path: 'givex-portal', canActivate: [GivexGuard], loadChildren: () => import('./givex-portal/givex-portal.module').then(m => m.GivexPortalModule)},
                        { path: 'ts-portal', canActivate: [TsGuard], loadChildren: () => import('./ts-portal/ts-portal.module').then(m => m.TsPortalModule)},
                        { path: 'vendor/:id', component: VendorComponent },
                        { path: 'wishlist', component: GuestWishlistComponent },
                        { path: 'signin-signup', component: LoginSignupComponent },
                        { path: 'forgot-password', component: ForgotPasswordComponent },
                        { path: 'reset-password/:token', component: ResetPasswordComponent },
                        { path: 'gift-card-balance', component: GiftCardBalanceComponent },
                        { path: 'ts-gift-card-balance', component: TsGiftCardBalanceComponent },
                        { path: 'pages/:id', component: CommonPageComponent },
                        { path: 'card_topup',
                            loadChildren: () => import('./single-page-checkout/single-page-checkout.module').then(m => m.SinglePageCheckoutModule)},
                        { path: 'card-topup', redirectTo: 'card_topup', pathMatch: 'full'},
                        { path: 'card_activation',
                            loadChildren: () => import('./single-page-checkout/single-page-checkout.module').then(m => m.SinglePageCheckoutModule)},
                        { path: 'card-activation', redirectTo: 'card_activation', pathMatch: 'full'},
                        {
                            path: 'user',
                            canActivate: [AuthGuard, CustomerGuard],
                            loadChildren: () => import('./user/user.module').then(m => m.UserModule)
                        },
                        { path: 'category/:id',
                            loadChildren: () => import('./product-listing/product-listing.module').then(m => m.ProductListingModule)},
                        { path: 'catalogsearch/result/:term',
                            loadChildren: () => import('./product-listing/product-listing.module').then(m => m.ProductListingModule)},
                        { path: 'cart',
                            loadChildren: () => import('./cart/cart.module').then(m => m.CartModule) },
                        { path: 'checkout',
                            loadChildren: () => import('./checkout/checkout.module').then(m => m.CheckoutModule)} ,
                        { path: ':category/:id/:sub',
                            loadChildren: () => import('./product-listing/product-listing.module').then(m => m.ProductListingModule)},
                        { path: ':category/:id/:sub/:sub1',
                            loadChildren: () => import('./product-listing/product-listing.module').then(m => m.ProductListingModule)},
                        { path: ':category/:id/:sub/:sub1/:sub2',
                            loadChildren: () => import('./product-listing/product-listing.module').then(m => m.ProductListingModule)},
                        { path: ':category/:id/:sub/:sub1/:sub2/:sub3',
                            loadChildren: () => import('./product-listing/product-listing.module').then(m => m.ProductListingModule)},
                        { path: ':category/:id/:sub/:sub1/:sub2/:sub3/:sub4',
                            loadChildren: () => import('./product-listing/product-listing.module').then(m => m.ProductListingModule)},
                        { path: ':category/:id/:sub/:sub1/:sub2/:sub3/:sub4/:sub5',
                            loadChildren: () => import('./product-listing/product-listing.module').then(m => m.ProductListingModule)},
                        { path: ':category/:id',
                            loadChildren: () => import('./product-listing/product-listing.module').then(m => m.ProductListingModule)},
                        {
                            path: ':id',
                            component: CommonRouteIdentifierComponent
                        }
                    ]
                }
            )
        }else{
            routes.push(
                {
                    path: '',
                    children: [
                        { path: 'givex-portal', canActivate: [GivexGuard],loadChildren: () => import('./givex-portal/givex-portal.module').then(m => m.GivexPortalModule)},
                        { path: 'ts-portal', canActivate: [TsGuard],loadChildren: () => import('./ts-portal/ts-portal.module').then(m => m.TsPortalModule)},
                        { path: 'vendor/:id', component: VendorComponent },
                        { path: 'wishlist', component: GuestWishlistComponent },
                        { path: 'signin-signup', component: LoginSignupComponent },
                        { path: 'forgot-password', component: ForgotPasswordComponent },
                        { path: 'reset-password/:token', component: ResetPasswordComponent },
                        { path: 'gift-card-balance', component: GiftCardBalanceComponent },
                        { path: 'ts-gift-card-balance', component: TsGiftCardBalanceComponent },
                        { path: 'pages/:id', component: CommonPageComponent },
                        {
                            path: 'user',
                            canActivate: [AuthGuard, CustomerGuard],
                            loadChildren: () => import('./user/user.module').then(m => m.UserModule)
                        },
                        { path: 'category/:id',
                            loadChildren: () => import('./product-listing/product-listing.module').then(m => m.ProductListingModule)},
                        { path: 'card_topup',
                            loadChildren: () => import('./single-page-checkout/single-page-checkout.module').then(m => m.SinglePageCheckoutModule)},                        
                        { path: 'card-topup', redirectTo: 'card_topup', pathMatch: 'full'},
                        { path: 'card_activation',
                            loadChildren: () => import('./single-page-checkout/single-page-checkout.module').then(m => m.SinglePageCheckoutModule)},
                        { path: 'card-activation', redirectTo: 'card_activation', pathMatch: 'full'},
                        { path: 'catalogsearch/result/:term',
                            loadChildren: () => import('./product-listing/product-listing.module').then(m => m.ProductListingModule)},
                        { path: 'cart',
                            loadChildren: () => import('./cart/cart.module').then(m => m.CartModule) },
                        { path: 'checkout',
                            loadChildren: () => import('./checkout/checkout.module').then(m => m.CheckoutModule)} ,
                        // { path: 'checkout/complete', component: CompleteComponent },
                        { path: ':category/:id/:sub',
                            loadChildren: () => import('./product-listing/product-listing.module').then(m => m.ProductListingModule)},
                        { path: ':category/:id/:sub/:sub1',
                            loadChildren: () => import('./product-listing/product-listing.module').then(m => m.ProductListingModule)},
                        { path: ':category/:id/:sub/:sub1/:sub2',
                            loadChildren: () => import('./product-listing/product-listing.module').then(m => m.ProductListingModule)},
                        { path: ':category/:id/:sub/:sub1/:sub2/:sub3',
                            loadChildren: () => import('./product-listing/product-listing.module').then(m => m.ProductListingModule)},
                        { path: ':category/:id/:sub/:sub1/:sub2/:sub3/:sub4',
                            loadChildren: () => import('./product-listing/product-listing.module').then(m => m.ProductListingModule)},
                        { path: ':category/:id/:sub/:sub1/:sub2/:sub3/:sub4/:sub5',
                            loadChildren: () => import('./product-listing/product-listing.module').then(m => m.ProductListingModule)},
                        { path: ':category/:id',
                            loadChildren: () => import('./product-listing/product-listing.module').then(m => m.ProductListingModule)},
                        {
                            path: ':id',
                            component: CommonRouteIdentifierComponent
                        }
                    ],
                },
                { path: 'vendors/:vendor_id/products',
                    loadChildren: () => import('./product-listing/product-listing.module').then(m => m.ProductListingModule) },
            );
        }
        this.router.resetConfig( routes );
    }

}
