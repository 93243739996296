import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from "./base.service"
import { CookieService } from 'ngx-cookie-service';
import { CookiesResetService } from './cookies-reset.service';

@Injectable()
export class LineItemService extends BaseService {

  protected http: HttpClient;
  currency;
  store;
  search;

  constructor(http: HttpClient, private cookieService: CookieService, public cookiesResetService: CookiesResetService) {
    super("line_items", http);
    this.http = http;
  }

  updateLineItem(lineItem, customOptions, message, variant_id?, quantity?, productForm?, editProduct?){
    var currency = this.cookiesResetService.get('curency')
    var store_id = this.cookiesResetService.get('store')
    var order_token = this.cookiesResetService.get('order_token')

    let options = {
      headers: new HttpHeaders(
          {
            'Content-type': 'application/json',
            'X-Store-Id': store_id,
            'X-Currency': currency,
            'X-Spree-Order-Token': order_token
          }
      )
    };

    let line_item_attributes = {
      "message": message,
      "customization_options": customOptions,
      "variant_id": variant_id
    };
    if(productForm){
      line_item_attributes["receipient_email"] = productForm['receipient_email'].value;
      line_item_attributes["receipient_first_name"] = productForm['receipient_first_name'].value;
      line_item_attributes["receipient_last_name"] = productForm['receipient_last_name'].value;
      line_item_attributes["receipient_phone_number"] = productForm['receipient_phone_number'].value;
      line_item_attributes["sender_name"] = productForm['sender_name'].value;
      if(productForm['delivery_mode_with_type'].value) line_item_attributes["delivery_mode"] = productForm['delivery_mode_with_type'].value;
    }

    if(quantity) line_item_attributes["quantity"] = quantity;
    if(editProduct) line_item_attributes["edit_product"] = editProduct;
    const body = JSON.stringify({ line_item_attributes, "cart_changed": true });

    return this.http.put(this.baseUrl + "/"+lineItem, body, options)
  }

  setRefundNotesLineItem(notes, lineItemId) {
    let myParams = new HttpParams();
    myParams = myParams.set("notes", notes)

    return this.http.put("/api/v2/storefront/request_refund/"+lineItemId, myParams);
  }

  uploadimage(file) {
    return this.http.post(this.baseUrl+ '/upload_image', file)
  }

  bulkUpdate(data){
    data = {line_items: data}
    let options = {
      headers: new HttpHeaders(
        {
          'Content-type': 'application/json',
          'X-Spree-Order-Token': this.cookiesResetService.get('order_token'),
          'X-Spree-Cart-Token': JSON.parse(this.cookiesResetService.getSessionItem('cart_token')) || 'null'
        }
      )
    };
    return this.http.put(this.baseUrl + "/bulk_update", data, options)
  }
}
